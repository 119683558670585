import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './calendar/calendar.component';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [CalendarComponent],
  exports: [CalendarComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    AngularCalendarModule,
    ButtonModule,
    ButtonGroupModule
  ]
})
export class CalendarModule {
  static forRoot(): ModuleWithProviders<AngularCalendarModule> {
    return AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    });
  }
}
