<div *transloco="let transloco" class="">
  <div
    class="flex flex-col items-center justify-center gap-4 rounded-t-lg border bg-white p-4 md:flex-row md:justify-between">
    <p-buttongroup class="rounded-lg border">
      <p-button
        (viewDateChange)="this.viewDateChanged($event)"
        [label]="transloco('previous')"
        [viewDate]="this.viewDate()"
        [view]="this.view()"
        icon="pi pi-arrow-left"
        mwlCalendarPreviousView />
      <p-button
        (viewDateChange)="this.viewDateChanged($event)"
        [label]="transloco('today')"
        [viewDate]="this.viewDate()"
        icon="pi pi-home"
        mwlCalendarToday
        severity="secondary" />
      <p-button
        (viewDateChange)="this.viewDateChanged($event)"
        [label]="transloco('next')"
        [viewDate]="this.viewDate()"
        [view]="this.view()"
        icon="pi pi-arrow-right"
        mwlCalendarNextView />
    </p-buttongroup>

    <div>
      <span class="block text-2xl">{{
        this.viewDate() | calendarDate: this.view() + 'ViewTitle'
      }}</span>
    </div>

    <p-buttongroup class="rounded-lg border">
      <p-button
        (click)="this.setView(this.CalendarView.Month)"
        [label]="transloco('month')"
        [severity]="this.view() === this.CalendarView.Month ? 'primary' : 'secondary'" />
      <p-button
        (click)="this.setView(this.CalendarView.Week)"
        [label]="transloco('week')"
        [severity]="this.view() === this.CalendarView.Week ? 'primary' : 'secondary'" />
      <p-button
        (click)="this.setView(this.CalendarView.Day)"
        [label]="transloco('day')"
        [severity]="this.view() === this.CalendarView.Day ? 'primary' : 'secondary'" />
    </p-buttongroup>
  </div>

  <div>
    @switch (this.view()) {
      @case (this.CalendarView.Month) {
        <mwl-calendar-month-view
          (beforeViewRender)="this.beforeMonthViewRender($event)"
          (dayClicked)="this.dayClicked($event.day)"
          (eventClicked)="this.eventClicked($event.event)"
          [events]="this.events()"
          [cellTemplate]="cellTemplate"
          [refresh]="this.refresh"
          [activeDayIsOpen]="this.activeDayIsOpen()"
          [weekStartsOn]="1"
          [viewDate]="this.viewDate()">
        </mwl-calendar-month-view>
      }
      @case (this.CalendarView.Week) {
        <mwl-calendar-week-view
          (eventClicked)="this.eventClicked($event.event)"
          [events]="this.events()"
          [refresh]="this.refresh"
          (dayHeaderClicked)="this.dayHeaderClicked($event.day.date)"
          [viewDate]="this.viewDate()">
        </mwl-calendar-week-view>
      }
      @case (this.CalendarView.Day) {
        <mwl-calendar-day-view
          (eventClicked)="this.eventClicked($event.event)"
          [events]="this.events()"
          [refresh]="this.refresh"
          [viewDate]="this.viewDate()">
        </mwl-calendar-day-view>
      }
    }

    <ng-template
      #cellTemplate
      let-day="day"
      let-eventClicked="eventClicked"
      let-highlightDay="highlightDay"
      let-locale="locale"
      let-openDay="openDay"
      let-tooltipAppendToBody="tooltipAppendToBody"
      let-tooltipDelay="tooltipDelay"
      let-tooltipPlacement="tooltipPlacement"
      let-tooltipTemplate="tooltipTemplate"
      let-trackByEventId="trackByEventId"
      let-unhighlightDay="unhighlightDay"
      let-validateDrag="validateDrag">
      <div
        [attr.aria-label]="{ day: day, locale: locale } | calendarA11y: 'monthCell'"
        class="cal-cell-top">
        <span aria-hidden="true">
          @if (day.badgeTotal > 0) {
            <span class="cal-day-badge">{{ day.badgeTotal }}</span>
          }
          <span class="cal-day-number">{{
            day.date | calendarDate: 'monthViewDayNumber' : locale
          }}</span>
        </span>
      </div>
      @if (day.events.length > 0) {
        <div class="cal-events">
          <div
            *ngFor="let event of this.getCellEvents(day.events); trackBy: trackByEventId"
            (mouseenter)="highlightDay.emit({ event: event })"
            (mouseleave)="unhighlightDay.emit({ event: event })"
            (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
            [attr.aria-hidden]="{} | calendarA11y: 'hideMonthCellEvents'"
            [class.cal-draggable]="event.draggable"
            [dragAxis]="{ x: event.draggable, y: event.draggable }"
            [dropData]="{ event: event, draggedFrom: day }"
            [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip' : event!"
            [ngClass]="event?.cssClass"
            [ngStyle]="{ backgroundColor: event.color?.primary }"
            [tooltipAppendToBody]="tooltipAppendToBody"
            [tooltipDelay]="tooltipDelay"
            [tooltipEvent]="event"
            [tooltipPlacement]="tooltipPlacement"
            [tooltipTemplate]="tooltipTemplate"
            [touchStartLongPress]="{ delay: 300, delta: 30 }"
            [validateDrag]="validateDrag"
            class="cal-event"
            dragActiveClass="cal-drag-active"
            mwlDraggable>
            <div class="">{{ event.title }}</div>
          </div>
          @if (day.events.length > 5) {
            <div class="cal-event !bg-primary text-center">
              {{ transloco('more_value', { value: day.events.length - 5 }) }}
            </div>
          }
        </div>
      }
    </ng-template>
  </div>
</div>
