import { CalendarDateFormatter, DateFormatterParams, getWeekViewPeriod } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  private translocoService = inject(TranslocoService);

  public override monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEEE', this.translocoService.getActiveLang());
  }

  public override monthViewTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'MMMM yyyy', this.translocoService.getActiveLang());
  }

  public override weekViewTitle({
    date,
    locale,
    weekStartsOn,
    excludeDays,
    daysInWeek
  }: DateFormatterParams): string {
    const { viewStart, viewEnd } = getWeekViewPeriod(
      this.dateAdapter,
      date,
      weekStartsOn || 0,
      excludeDays,
      daysInWeek
    );

    const format = (dateToFormat: Date, showYear: boolean) =>
      formatDate(
        dateToFormat,
        'd MMMM' + (showYear ? ', yyyy' : ''),
        this.translocoService.getActiveLang()
      );
    return `${format(
      viewStart,
      viewStart.getUTCFullYear() !== viewEnd.getUTCFullYear()
    )} - ${format(viewEnd, true)}`;
  }

  public override weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEEE', this.translocoService.getActiveLang());
  }

  public override weekViewColumnSubHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'd MMM', this.translocoService.getActiveLang());
  }

  public override weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', this.translocoService.getActiveLang());
  }

  public override dayViewTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEEE, dd MMMM yyyy ', this.translocoService.getActiveLang());
  }

  public override dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', this.translocoService.getActiveLang());
  }
}
