import { Component, input, output, signal } from '@angular/core';
import { isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarDateFormatter,
  CalendarEvent as AngularCalendarEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarView
} from 'angular-calendar';
import { CustomDateFormatter } from '../injectables/custom-date-formatter';

export interface CalendarEvent<MetaType = any> extends AngularCalendarEvent<MetaType> {
  onClick?: (even: CalendarEvent) => any;
}

export interface ViewPeriod {
  start: Date;
  end: Date;
}

@Component({
  selector: 'lib-calendar',
  standalone: false,
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.css',
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class CalendarComponent {
  readonly defaultView = input<CalendarView>(CalendarView.Month);
  readonly events = input<CalendarEvent[]>([]);

  readonly periodChanged = output<ViewPeriod>();

  readonly view = signal<CalendarView>(this.defaultView());
  readonly viewDate = signal<Date>(new Date());
  readonly activeDayIsOpen = signal<boolean>(false);

  readonly CalendarView = CalendarView;

  refresh = new Subject<void>();

  constructor() {}

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate())) {
      if ((isSameDay(this.viewDate(), date) && this.activeDayIsOpen()) || events.length === 0) {
        this.closeOpenMonthViewDay();
      } else {
        this.activeDayIsOpen.set(true);
      }
      this.viewDate.set(date);
    }
  }

  eventClicked(event: CalendarEvent) {
    if (event.onClick) {
      event.onClick(event);
    }
  }

  viewDateChanged(event: Date) {
    this.closeOpenMonthViewDay();
    this.viewDate.set(event);
  }

  setView(view: CalendarView) {
    if (view != CalendarView.Month) {
      this.closeOpenMonthViewDay();
    }
    this.view.set(view);
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen.set(false);
  }

  getCellEvents(events: any) {
    return events.slice(0, 5);
  }

  viewPeriod: ViewPeriod;
  beforeMonthViewRender(event: CalendarMonthViewBeforeRenderEvent) {
    if (
      !this.viewPeriod ||
      !isSameDay(this.viewPeriod.start, event.period.start) ||
      !isSameDay(this.viewPeriod.end, event.period.end)
    ) {
      this.viewPeriod = event.period;
      this.periodChanged.emit(this.viewPeriod);
    }
  }

  dayHeaderClicked(event: Date) {
    this.viewDateChanged(event);
    this.view.set(CalendarView.Day);
  }
}
